import { Box, HStack, Heading, Image, Stack } from '@chakra-ui/react';
import React from 'react';

const Hero = () => {
  return (
    <HStack
      w={'full'}
      h={{ base: 'auto', lg: '500px' }}
      overflow={'hidden'}
      position={'relative'}
    >
      <Box
        w={'full'}
        h={{ base: 'auto', lg: '500px' }}
        position={'absolute'}
        zIndex={3}
        bg={'rgba(0,0,0,0.5)'}
        top={0}
      ></Box>
      <Image
        src={'/images/bgHero.jpg'}
        alt={'hero image'}
        w={'full'}
        objectFit={'contain'}
        position={'absolute'}
        top={0}
        zIndex={1}
      />
      <Stack w={'full'} alignItems={'center'} justifyContent={'center'}>
        <Stack
          gap={'1rem'}
          alignItems={{ base: 'center', lg: 'flex-start' }}
          color={'contrast'}
          zIndex={5}
        >
          <Heading
            as={'h2'}
            fontWeight={'light'}
            fontSize={{ base: '1rem', lg: '2rem' }}
          >
            Partenerul tau in digitalizare
          </Heading>
          <Heading
            as={'h1'}
            fontSize={{ base: '2rem', lg: '3rem' }}
            fontWeight={'bold'}
          >
            Digidev Innotech
          </Heading>
        </Stack>
      </Stack>
    </HStack>
  );
};

export default Hero;
