import { Divider, HStack, Heading, Image, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const menuItems = [
    {
      nume: 'Acasa',
      link: '/',
    },
    {
      nume: 'Despre',
      link: '/despre',
    },
    {
      nume: 'Proiecte',
      link: '/proiecte',
    },
    {
      nume: 'Contact',
      link: '/contact',
    },
  ];
  return (
    <Stack
      w={'full'}
      px={{ base: '1rem', lg: '4rem' }}
      py={'2rem'}
      alignItems={'center'}
      justifyContent={'center'}
      bg={'main'}
      color={'contrast'}
      gap={'2rem'}
    >
      <HStack
        w={'full'}
        maxW={{ base: 'full', lg: '80rem' }}
        justifyContent={{ base: 'center', lg: 'space-evenly' }}
        alignItems={{ base: 'center', lg: 'flex-start' }}
        flexWrap={'wrap'}
        gap={{ base: '2rem', lg: 0 }}
      >
        <Stack>
          <Image
            w={'200px'}
            src={'/images/logo2.png'}
            alt={'Digidev Innotech'}
          />
          <Heading as={'h2'} fontSize={'1.5rem'}>
            Digitalizarea este VIITORUL!
          </Heading>
        </Stack>
        <Stack gap={'1rem'} w={{ base: '300px', lg: 'auto' }}>
          <Heading
            as={'h3'}
            fontSize={'1.5rem'}
            textAlign={{ base: 'center', lg: 'left' }}
          >
            Categorii
          </Heading>
          <HStack
            gap={'2rem'}
            maxW={{ base: '300px', lg: '20rem' }}
            flexWrap={'wrap'}
            justifyContent={'space-between'}
          >
            {menuItems.map((item) => (
              <Link key={item.nume} to={item.path}>
                <Text textTransform={'capitalize'} textDecoration={'underline'}>
                  {item.nume}
                </Text>
              </Link>
            ))}
          </HStack>
        </Stack>
        <Stack gap={'1rem'}>
          <Heading
            as={'h3'}
            fontSize={'1.5rem'}
            textAlign={{ base: 'center', lg: 'left' }}
          >
            Legal
          </Heading>
          <Stack>
            <Link to={'/gdpr'}>
              <Text textTransform={'capitalize'} textDecoration={'underline'}>
                politica de confidențialitate
              </Text>
            </Link>
            <Link to={'/termeni-conditii'}>
              <Text textTransform={'capitalize'} textDecoration={'underline'}>
                termeni și condiții
              </Text>
            </Link>
            <Link to={'/cookies'}>
              <Text textTransform={'capitalize'} textDecoration={'underline'}>
                politica cookie
              </Text>
            </Link>
          </Stack>
        </Stack>
      </HStack>
      <Divider />
      <HStack>
        <Text>Creat de Digidev Innotech S.R.L</Text>
        <Text>
          <strong>&#9400; {new Date().getFullYear()}</strong>
        </Text>
      </HStack>
    </Stack>
  );
};

export default Footer;
