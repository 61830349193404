import { HStack, Stack, Text, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';

const MobileNav = ({ isOpen, toggle }) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const menuItems = [
    {
      nume: 'Acasa',
      link: '/',
    },
    {
      nume: 'Despre',
      link: '/despre',
    },
    {
      nume: 'Proiecte',
      link: '/proiecte',
    },
    {
      nume: 'Contact',
      link: '/contact',
    },
  ];
  return (
    <Stack
      className="mobileNav"
      display={isDesktop ? 'none' : 'flex'}
      w={'250px'}
      top={0}
      transition={'all .8s ease-in-out'}
      right={isOpen ? 0 : '-300px'}
      position={'fixed'}
      zIndex={10001}
      bg={'main'}
      color={'contrast'}
      h={'100vh'}
      px={'2rem'}
      py={'2rem'}
      gap={'1.4rem'}
    >
      <HStack w={'full'} justifyContent={'flex-end'}>
        {' '}
        <CloseIcon color={'#fff'} fontSize={'1.5rem'} onClick={toggle} />
      </HStack>
      {menuItems.map((item) => (
        <Link onClick={toggle} key={item.nume} to={item.link}>
          <Text
            textAlign={'right'}
            fontSize={'1.25rem'}
            className="signika-bold"
            _hover={{
              borderBottom: '3px solid',
              borderBottomColor: 'contrast',
            }}
            textTransform={'capitalize'}
          >
            {item.nume}
          </Text>
        </Link>
      ))}
    </Stack>
  );
};

export default MobileNav;
