import { Stack, Text } from '@chakra-ui/react';
import { useEffect } from 'react';
// import LoadingBox from '../LoadingBox';
// import MessageBox from '../MessageBox';
import Aos from 'aos';
import 'aos/dist/aos.css';

function Projects() {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <Stack
      w={'full'}
      alignItems={'center'}
      justifyContent={'center'}
      h={'400px'}
      bg={'main'}
    >
      <Text
        fontWeight={'bold'}
        w={'60rem'}
        fontSize={'1.5rem'}
        textAlign={'center'}
        color={'contrast'}
      >
        Bine ați venit la DigiDev Innotech - locul unde inovația întâlnește
        tehnologia pentru a crea soluții digitale de excepție. Suntem o echipă
        dedicată pasionată de transformarea digitală și de crearea unor
        experiențe online memorabile. De la dezvoltarea de aplicații web
        personalizate la strategii avansate de marketing digital și consultanță
        în tehnologie, ne angajăm să fim partenerul dumneavoastră de încredere
        în călătoria către succesul online.
      </Text>
    </Stack>
  );
}

export default Projects;
