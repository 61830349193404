import {
  HStack,
  IconButton,
  Image,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { FiMenu } from 'react-icons/fi';

const Navbar = ({ api, toggle, logo }) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const menuItems = [
    {
      nume: 'Acasa',
      link: '/',
    },
    {
      nume: 'Despre',
      link: '/despre',
    },
    {
      nume: 'Proiecte',
      link: '/proiecte',
    },
    {
      nume: 'Contact',
      link: '/contact',
    },
  ];
  return (
    <HStack
      w={'full'}
      justifyContent={'space-between'}
      px={{ base: '1rem', lg: '3rem' }}
      py={'1rem'}
      bg={'main'}
      color={'contrast'}
      zIndex={1000}
    >
      <Link to="/">
        <Image w={'250px'} src={'/images/logo2.png'} alt={'nume ziar'} />
      </Link>
      <HStack gap={'2rem'}>
        {isDesktop ? (
          menuItems.map((item) => (
            <Link key={item.nume} to={item.link}>
              <Text
                fontSize={'1.25rem'}
                fontWeight={'bold'}
                textTransform={'uppercase'}
                _hover={{
                  borderBottom: '3px solid',
                  borderBottomColor: 'contrast',
                }}
              >
                {item.nume}
              </Text>
            </Link>
          ))
        ) : (
          <>
            <IconButton
              variant="ghost"
              color={'textLight'}
              icon={<FiMenu fontSize="1.5rem" />}
              aria-label="Open Menu"
              _hover={'none'}
              _active={'none'}
              _focus={'none'}
              onClick={toggle}
            />
          </>
        )}
      </HStack>
    </HStack>
  );
};

export default Navbar;
