import { extendTheme } from '@chakra-ui/react';
export const colorMode = extendTheme({
  initialColorMode: 'dark',
});
export const myTheme = extendTheme({
  colors: {
    text: '#250C17',
    bg: '#f0f0f0',
    main: '#6B001B',
    contrast: '#f1eaee',
    overlay: 'rgba(0,0,0,0.5)',
    glass: 'rgba(0,0,0,0.2)',
  },
});
