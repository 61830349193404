import React from 'react';
import { Hero, Process, Projects, Solutions, Techs } from '../components';
import { Stack } from '@chakra-ui/react';

const Home = () => {
  return (
    <>
      <Stack w={'full'} alignItems={'center'} position={'relative'} gap={0}>
        <Hero />
        <Projects />
        <Process />
        {/* <Solutions />
        <Techs /> */}
      </Stack>
    </>
  );
};

export default Home;
