import { Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { PageHeader } from '../components';

const Despre = () => {
  return (
    <Stack w={'full'} alignItems={'center'} position={'relative'} gap={0}>
      <PageHeader titlu={'Despre '} />
      <Stack w={'full'} maxW={'100rem'} alignItems={'center'} py={'2rem'}>
        <Text whiteSpace={'no-wrap'} w={'50rem'} fontSize={'1.25rem'}>
          Cu sediul în centrul inovației tehnologice,{' '}
          <strong>DigiDev Innotech</strong> este o companie de vârf specializată
          în dezvoltarea de aplicații web, furnizând soluții digitale de ultimă
          generație și servicii de consultanță adaptate nevoilor diversificate
          ale clienților noștri.
          <br />
          <br />
          Echipa noastră talentată și experimentată este dedicată să ofere
          produse și servicii de cea mai înaltă calitate, menite să transforme
          și să optimizeze prezența online a afacerilor. De la aplicații web
          personalizate și platforme de e-commerce până la soluții de gestionare
          a conținutului și sisteme de administrare a bazelor de date, ne
          străduim să oferim inovație și performanță în fiecare proiect. <br />
          <br /> Cu o abordare centrată pe nevoile clienților și o atenție
          deosebită acordată detaliilor, suntem dedicați să dezvoltăm soluții
          digitale care să răspundă cu precizie cerințelor și obiectivelor
          afacerii dvs. Fie că sunteți în căutarea unei aplicații web complexe
          sau a unei soluții simplificate și eficiente, suntem aici să vă ajutăm
          să vă transformați viziunea în realitate.
          <br />
          <br /> În plus față de dezvoltarea de aplicații web, oferim și
          servicii complementare, precum consultanță în strategii digitale,
          integrare API, optimizare pentru motoarele de căutare (SEO) și analiză
          a performanței online. Suntem dedicați să fim partenerul de încredere
          al afacerii dvs. în călătoria către succesul digital, oferind suport
          și expertiză în fiecare etapă a procesului. <br />
          <br />
          La DigiDev Innotech, credem că inovația și tehnologia sunt forțele
          motrice ale progresului. Prin soluțiile noastre digitale avansate și
          serviciile de consultanță personalizate, ne angajăm să fim în fruntea
          acestei transformări, ajutând clienții noștri să își maximizeze
          potențialul și să își atingă obiectivele în mediul online în continuă
          schimbare.
        </Text>
      </Stack>
    </Stack>
  );
};

export default Despre;
