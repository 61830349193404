import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import ScrollToTop from './utils/ScrollToTop';
import { useState } from 'react';
import { Footer, MobileNav, Navbar } from './components';
import { Stack } from '@chakra-ui/react';
import Home from './pages/Home';
import Despre from './pages/Despre';
import Contact from './pages/Contact';
function App() {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Router>
      <ScrollToTop />
      <Navbar toggle={toggle} />
      <MobileNav isOpen={isOpen} toggle={toggle} />
      <Stack
        w={'full'}
        minH={'calc(100vh - 133px - 286px)'}
        alignItems={'center'}
      >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/despre" element={<Despre />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Stack>
      <Footer />
    </Router>
  );
}

export default App;
