import {
  Box,
  Heading,
  ListIcon,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import React from 'react';
import { GiDiscussion, GiLightBulb, GiTransform } from 'react-icons/gi';
import { MdDeveloperMode, MdDesignServices } from 'react-icons/md';
import { FaPeopleArrows } from 'react-icons/fa';

function Process() {
  const process = [
    {
      image: GiDiscussion,
      title: 'Dialogam',
      text: 'Ceva descriere',
      effect: 'fade-down-right',
      effectDuration: '1500',
      effectEase: 'ease-in-out',
      effectDelay: 100,
    },
    {
      image: GiLightBulb,
      title: '2. Proposition',
      text: 'Based on the previous discussion I present the best solution',
      effect: 'fade',
      effectDuration: '2200',
      effectEase: 'ease-in-out',
      effectDelay: 300,
    },
    {
      image: MdDeveloperMode,
      title: '3. Developing',
      text: 'I work in sprints to develop the solution and present to you each module',
      effect: 'fade',
      effectDuration: '2200',
      effectEase: 'ease-in-out',
      effectDelay: 300,
    },
    {
      image: GiTransform,
      title: '4. Adaptation',
      text: 'Based on the feedback of the modules I adapt the application to fit perfectly with your needs',
      effect: 'fade-down-left',
      effectDuration: '1500',
      effectEase: 'ease-in-out',
      effectDelay: 100,
    },
  ];

  const texts = [
    {
      image: FaPeopleArrows,
      title: 'Best solutions for my partners!',
      text: 'My ideology is that I have partners not clients, on the grounds that partners collaborate on the long term, as clients come and go.\u000A\u000AAnd so I offer my partners a web application that is modern, fast and with a low maintenance cost ',
    },
    {
      image: MdDesignServices,
      title: 'What I build?',
      text: `The web applications that I build are secure, flexible and scalable, with an attractive UI that makes the user experience a delight.\u000A\u000AI work with the latest technologies based on javascript, both on back end and front end.\u000A\u000AThe UI/UX is built from scratch so it can mold on your type of business. I focus on efficiency, so that my partners activities can be boosted.`,
    },
  ];
  return (
    <Box
      maxW={'100rem'}
      w={'full'}
      display={'flex'}
      flexDir={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      gap={'5rem'}
      my={'5rem'}
    >
      <Heading as={'h2'} fontSize={'2rem'}>
        Cum decurge procesul nostru de colaborare?
      </Heading>
      <Box
        w={'full'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        flexWrap={'wrap'}
      >
        {process.map((item, index) => (
          <Box
            key={index}
            maxW={'300px'}
            data-aos={item.effect}
            data-aos-duration={item.effectDuration}
            data-aos-easing={item.effectEase}
            data-aos-delay={item.effectDelay}
          >
            <UnorderedList
              listStyleType={'none'}
              m={0}
              display={'flex'}
              flexDir={'column'}
              alignItems={'center'}
              gap={'2rem'}
            >
              <ListItem>
                <ListIcon
                  boxSize={'100px'}
                  as={item.image}
                  color={'brand.500'}
                />
              </ListItem>
              <ListItem>
                <Heading as={'h3'} fontSize={'h3'}>
                  {item.title}
                </Heading>
              </ListItem>
              <ListItem>
                <Text textAlign={'center'} w={'fit-content'}>
                  {item.text}
                </Text>
              </ListItem>
            </UnorderedList>
          </Box>
        ))}
      </Box>
      <Box
        display={'flex'}
        w={'full'}
        flexWrap={'wrap'}
        justifyContent={'space-evenly'}
        alignItems={'flex-start'}
        position={'relative'}
        color={'brand.400'}
      >
        {texts.map((text, index) => (
          <Box
            color={'main'}
            position={'relative'}
            zIndex={3}
            className="glass"
            display={'flex'}
            flexDir={'column'}
            gap={'2rem'}
            key={index}
            w={'full'}
            minH={'200px'}
            maxW={'700px'}
            bg={'glass'}
            py={'1rem'}
            px={'2rem'}
            mb={'2rem'}
          >
            <Box
              display={'flex'}
              alignItems={'center'}
              w={'full'}
              justifyContent={'space-between'}
            >
              <Heading as={'h3'} fontSize={'h4'}>
                {text.title}
              </Heading>
              <UnorderedList listStyleType={'none'}>
                <ListItem>
                  <ListIcon fontSize={'3rem'} as={text.image} />
                </ListItem>
              </UnorderedList>
            </Box>
            <Text whiteSpace={'pre-wrap'} fontWeight={'600'} w={'full'}>
              {text.text}
            </Text>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default Process;
